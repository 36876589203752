import './buildingPreview.css';
import { Button } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useState } from 'react';

const BuildingPreview = ({building, cardMode = false}) => {
    const [cardModeOn, setCardModeOn] = useState(cardMode);
    const path = useLocation();

    const handleCardMode= () => {
        if(path.pathname == '/Desarrollos')
            window.innerWidth  < 1100 ? setCardModeOn(true) : setCardModeOn(false);
    }
  
    useEffect(() => {
        window.addEventListener('resize', handleCardMode);
        handleCardMode();
    }, []);
    
    return(
        <div className={`buildingPreview_container`}>
            <div className={`buildingPreview ${cardModeOn ? 'cardMode' : ''}`}>
                <div className={`buildingPreview_imgContainer ${cardModeOn ? 'cardMode' : ''}`} > 
                    <Link to={building.url}>
                        <img src={building.img} width={'100%'} height={'100%'} loading='lazy' alt='Fachada del Edificio'/>
                    </Link>
                </div>
                <div className={`buildingPreview_textContainer ${cardModeOn ? 'cardMode' : ''} ${path.pathname == '/Manilu1' ? 'manilu1' : ''}`}>
                    <div className='name_container'>
                        <h2>
                            <span className='gold_letters'>{building.name[0]}</span><br/>
                            {building.name[1]}<br/>
                            {building.name[2]}<br/>
                        </h2>
                    </div>
                    <div className='ubi_container'>
                        <span>
                            {building.ubi[0]}<br/>
                            {building.ubi[1]}
                        </span>
                    </div>
                    <div className='btn_container'>
                        <Button className='btn' variant='outlined' endIcon={<ArrowForwardIcon />}>
                            <Link to={building.url}>
                                Ver mas
                            </Link>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BuildingPreview;