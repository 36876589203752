import React, { lazy, Suspense } from 'react';
import './slider.css';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Skeleton } from '@mui/material';

const BannerElement = lazy(() => import('../bannerElement/bannerElement'));

function Slider({ items, controls }) {
  return (
    <>
        <Carousel className='slider' variant="dark" controls={controls} interval={4000} slide={true} touch={true}>
            {
                items.map((item, key) => {
                    return(
                        <Carousel.Item className='carousel_item' key={key}>
                            <Suspense fallback={<Skeleton />}> 
                                <BannerElement 
                                    imageUrl = {item.imageUrl}
                                    title = {item.title}
                                    text = {item.text}
                                    btn = {item.btn}
                                    btnUrl = {item.btnUrl}
                                    imgPosition = {item.imgPosition !== undefined ? item.imgPosition : 'center center'}
                                    disponible = {item.disponible ? item.disponible : 0}
                                    size = {item.size ? item.size : 'cover'}
                                />
                            </Suspense>
                        </Carousel.Item>
                    );
                })
            }
        </Carousel>
    </>
  );
}

export default Slider;