import './footer.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import RoomIcon from '@mui/icons-material/Room';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { Divider } from '@mui/material';


const Footer = () => {
    return(
        <div className='footer'>
            <div className='footer_container'>
                <div className='redes_container'>
                    <a href='https://www.instagram.com/edificioshonolulu/' target='_blank'><InstagramIcon className='icons'/>edificiosHonolulu</a>
                    <a href='https://www.facebook.com/EdificiosHonolulu' target='_blank'><FacebookIcon className='icons'/>edificiosHonolulu</a>
                </div>
                <div className='footer_divider1'>
                    <Divider className='divider_top'/>
                    <Divider className='divider_mid'/>
                    <Divider className='divider_bot'/>
                </div>
                <div className='ventas_container'>
                    <div className='ventas_text_container'>
                        <h3>VENTAS</h3>
                        <span><PhoneIcon/>+54 223 5416401</span>
                        <span><PhoneIcon/>+54 223 5416717</span>
                        <span><EmailIcon/>edificioshonolulu@gmail.com</span>
                    </div>
                </div>
                <div className='footer_divider2'>
                    <Divider className='divider_top'/>
                    <Divider className='divider_mid'/>
                    <Divider className='divider_bot'/>
                </div>
                <div className='ubi_container'>
                    <div className='ubi_text_container'>
                        <span>
                            <RoomIcon />
                        </span>
                        <p>
                            Calle M. Strobel 3562 <br/>
                            Oficina 102, Mar del Plata,<br/>
                            Buenos Aires.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;