import { useEffect, useState } from 'react';
import './navBar.css';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { Button } from '@mui/material';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

function NavBar() {
  const [fixed, setFixed] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const path = useLocation();


  const handleMenu = () => {
    if(window.innerWidth < 900)
      openMenu ? setOpenMenu(false) : setOpenMenu(true);
  }

  const handleMenuIcon = () => {
    if(openMenu)
      setOpenMenu(false);
  }


  return (
    <div className={`navBar ${path.pathname == "/" ? 'navBar_trans' : ''} ${path.pathname !== "/" ? 'fixed navBar_bg' : '' } ${window.innerWidth < 900 && openMenu ? 'navBar_bg' : ''}`}> 
        <div className={`navBar_container`}> 
            <div className='navBar_logo'>
              <Link to={'./'} onClick={handleMenuIcon}>
                  {
                    path.pathname !== "/" || openMenu? 
                    (
                      <img src='../../assets/Logo/LogoDorado.png' width={'100%'} height={'100%'} loading='lazy' alt='Logo de la pagina'/>
                    )
                    :
                    (
                      <span></span>
                    )
                  }
              </Link> 
            </div>
            <div className='menuIcon_container'>
              <Button onClick={handleMenu} className={`menuIcon_btn ${window.innerWidth < 900 && path.pathname !== "/" ? 'open' : ''}`}>
                <MenuIcon/>
              </Button>
            </div>
            <div className={`navBar_list_container ${openMenu ? 'open' : ''}`}>
                <ul className={`navBar_list ${openMenu ? 'open' : ''}`}>
                    <li className='navBar_list_item'>
                      <Link to={'./'} onClick={handleMenu}>Inicio</Link> 
                    </li>
                    <li className={`navBar_list_item desarrollos`}>
                      <Link to={'/Desarrollos'} onClick={handleMenu}>Desarrollos</Link> 
                      <ul className={`desarrollos_subList`}>
                          <li className='subList_items'>
                            <Link to={'/Honolulu3'} onClick={handleMenu}>
                              <KeyboardArrowRightOutlinedIcon />Honolulu 3
                            </Link>
                          </li>
                          <li className='subList_items'>
                            <Link to={'/Honolulu2'} onClick={handleMenu}>
                              <KeyboardArrowRightOutlinedIcon />Honolulu 2
                            </Link>
                          </li>
                          <li className='subList_items'>
                            <Link to={'/Honolulu1'} onClick={handleMenu}>
                              <KeyboardArrowRightOutlinedIcon />Honolulu 1
                            </Link>
                          </li>
                          <li className='subList_items'>
                            <Link to={'/Manilu2'} onClick={handleMenu}>
                              <KeyboardArrowRightOutlinedIcon />Manilu 2
                            </Link>
                          </li>
                          <li className='subList_items'>
                            <Link to={'/Manilu1'} onClick={handleMenu}>
                              <KeyboardArrowRightOutlinedIcon />Manilu 1
                            </Link>
                          </li>
                      </ul>
                    </li>
                    <li className='navBar_list_item'>
                      <Link to={'./Empresa'} onClick={handleMenu}>La empresa</Link> 
                    </li>
                    <li className='navBar_list_item'>
                      <Link to={'./Contacto'} onClick={handleMenu}>Contacto</Link> 
                    </li>
                </ul>
            </div>
        </div>
    </div>
  );
}

export default NavBar;
