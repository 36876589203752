import { Divider } from '@mui/material';
import FormContact from '../formContact/formContact';
import './contactUs.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import RoomIcon from '@mui/icons-material/Room';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';


const ContactUs = () => {
    return(
        <div className='contact_container'>
            <div className='title_container'>
                <Divider className='title_container_divider'/>
                <h2 className='title_container_title'>
                    Contacto
                </h2>
                <Divider className='title_container_divider'/>
            </div>
            <div className='contact_data_container'>
                <div className='contact_form_container'>
                    <FormContact numAmbientes={3}/>
                </div>
                <div className='contact_divider'>
                    <Divider className='divider_top'/>
                    <Divider className='divider_mid'/>
                    <Divider className='divider_bot'/>
                </div>
                <div className='contact_info_container'>
                    <div className='info_container_center'> 
                        <div className='contact_ubi'>
                            <span>
                                <RoomIcon />
                            </span>
                            <p>
                                Calle M.Strobel 3562 oficina 102, <br/>
                                Mar del Plata, Buenos Aires.
                            </p>
                        </div>
                        <span><PhoneIcon/>+54 223 5416401</span>
                        <span><PhoneIcon/>+54 223 5416717</span>
                        <span><EmailIcon/>edificioshonolulu@gmail.com</span>
                        <span><a href='https://www.facebook.com/EdificiosHonolulu' target='_blank'><FacebookIcon/>edificiosHonolulu</a></span>
                        <span><a href='https://www.instagram.com/edificioshonolulu/' target='_blank'><InstagramIcon/>edificiosHonolulu</a></span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;