import { useEffect, useState } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import HomePage from '../components/homePage/homePage';
import NavBar from '../components/navBar/navBar';
import BuildingPage from '../components/buildingPage/buildingPage';
import Developments from '../components/developments/developments';
import buildingPagesDesktop from '../jsonObjects/jsonDesktop/buildingPage.json';
import buildingPagesMobile from '../jsonObjects/jsonMobile/buildingPage.json';
import BuildingSoldOutPage from '../components/buildingSoldOutPage/buildingSoldOutPage';
import ScrollToTop from '../scrollToTop/scrollToTop';
import Footer from '../components/footer/footer';
import ContactUs from '../components/contacUs/contacUs';
import AboutUs from '../components/aboutUs/aboutUs';



const AppRouter = () => {
    const [buildingPages, setBuildingPages] = useState(buildingPagesMobile);

    const handlePhotos = () => {
        if(window.innerWidth > 1100)
            setBuildingPages(buildingPagesDesktop);
        else    
            setBuildingPages(buildingPagesMobile);
    }

    useEffect( () => {
        window.addEventListener('resize', handlePhotos);
    }, []);
    return(
        <>
            <BrowserRouter>
                <ScrollToTop />
                <NavBar/>
                <Routes>
                    <Route path="/Desarrollos" element={<Developments />}/>
                    <Route path="/Honolulu1" element={<BuildingPage building={buildingPages.buildingPageHonolulu1}/>}/>
                    <Route path="/Honolulu2" element={<BuildingPage building={buildingPages.buildingPageHonolulu2}/>}/>
                    <Route path="/Honolulu3" element={<BuildingPage building={buildingPages.buildingPageHonolulu3}/>}/>
                    <Route path="/Manilu2" element={<BuildingPage building={buildingPages.buildingPageManilu2}/>}/>
                    <Route path="/Manilu1" element={<BuildingSoldOutPage building={buildingPages.buildingPageManilu1}/>}/>
                    <Route path="/Contacto" element={<ContactUs/>}/>
                    <Route path="/Empresa" element={<AboutUs/>}/>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="*"/>
                </Routes>
                <Footer/>
            </BrowserRouter>
        </>
    );
}

export default AppRouter;