import './App.css';
import AppRouter from './appRouter/appRouter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

function App() {
  return (
    <div className="App">
      <AppRouter/>
      <div className='iconos_redes_container'>
        <a 
          href="https://wa.me/5492235416401" 
          target='_blank'
          rel="noopener noreferrer"
        > 
          <WhatsAppIcon htmlColor='#fff' className='wpp_icon'/>
        </a>
        <a href='https://www.instagram.com/edificioshonolulu/' target='_blank'><InstagramIcon className='ig_icon'/></a>
        <a href='https://www.facebook.com/EdificiosHonolulu' target='_blank'><FacebookIcon className='fc_icon'/></a>
      </div>
    </div>
  );
}

export default App;
