import React, { lazy, Suspense } from 'react';
import './buildingPage.css';
import Divider from '@mui/material/Divider';
import { Skeleton } from '@mui/material';
import Slider from '../slider/slider';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

const BannerElement = lazy(() => import('../bannerElement/bannerElement'));

function BuildingPage({building}) {
  return (
    <>
        <div className='title_container'>
            <Divider className='title_container_divider'/>
            <h2 className='title_container_title'>
                {building.name}
            </h2>
            <Divider className='title_container_divider'/>
        </div>
        <Suspense fallback={<Skeleton />}>
            <BannerElement 
                imageUrl= {building.bannerElementInfo.imgUrl}
                title= {building.bannerElementInfo.title}
                text= ''
                btn= 'none'
                btnUrl= ''
                numAmbientes={building.bannerElementInfo.numAmbientes}
            />
        </Suspense>
        <div className='carac_container'>
            <div className='carac_container_info'>
               <p dangerouslySetInnerHTML={{__html:building.caracInfo.text}}></p>
            </div>
            <div className='carac_container_img'>
                <img src={building.caracInfo.imgBottomUrl} width={'90%'} height={'100%'} loading='lazy' className='img_blur'alt='Fachada del edificio'/>
                <img src={building.caracInfo.imgTopUrl} width={'90%'} height={'100%'} loading='lazy' className='img_top'alt='Fachada del edificio'/>
            </div>
        </div>
        <div className='info_container'>
            <div className='info_container_brochure'>
                <div className='brochure_container'>
                    <div>
                        <h6>Brochure</h6>
                        <ul>
                            <li><ArrowForwardIcon/><a href={building.brochureInfo.brochureUrl} target='_blank'>Descargar</a></li>
                        </ul>
                    </div>
                    <div>
                        <h6>Planos</h6>
                        <ul>
                            {
                                building.brochureInfo.planosList.map((item, key) =>{
                                    return(
                                        <li key={key}><ArrowForwardIcon/>
                                            <a href={item.planoUrl} target='_blank'>{item.title}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>       
            <div className='info_container_divider'>
                <Divider orientation='vertical' className='info_container_divider_divider'/>
            </div>  
            <div className='info_container_text'>
                <ul>
                    {
                        building.brochureInfo.description.map((item, key) => {
                            return(
                                <li key={key}><KeyboardArrowRightOutlinedIcon/>{item}</li>
                            )
                        })
                    }
                </ul>
            </div>
                
        </div>
        <div className='buildinPage_grid_container'>
            <div className='title_container'>
                <Divider className='title_container_divider'/>
                <h2 className='title_container_title'>El Proyecto</h2>
                <Divider className='title_container_divider'/>
            </div>
            <div className='buildinPage_slider_container'>
                <Slider items={building.imagesGrid} controls={true}/>
            </div>
        </div>
        <div className='buildingPage_ubi_container'>
            <div className='ubi_text'>
                <h2>Ubicación</h2>
                <p dangerouslySetInnerHTML={{__html:building.ubiInfo.text}}></p>
            </div>
            <div className='ubi_map'>
                <iframe 
                    src={building.ubiInfo.mapUrl}
                    width="100%"
                    height="450" 
                    allowFullScreen="" 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </div>
    </>
  );
}

export default BuildingPage;