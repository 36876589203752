import './formContact.css';
import { Button, TextField } from '@mui/material';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const FormContact = ({ numAmbientes }) => {
    const ambientes = [];
    const form = useRef();

    for(let i = 0; i < numAmbientes; i++){
        ambientes.push(
        {
            value: (i + 1) + ' ambientes'
        });
    }


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_m0mjn8y","template_ow21cpy", form.current, 'xqvAWNDmc_n9rqPkE')
    .then((result) => {
          console.log(result.text);
          form.current.reset();
      }, (error) => {
          console.log(error.text);
      });
  };


    return(
        <div className='formContact_container' style={{display: ambientes.length == 0 ? 'none' : ''}}>
                <form className='formContact' ref={form} onSubmit={sendEmail}>
                    <div className='formContact_text'>
                        <h2>Queremos <span className='gold_letters'>asesorarte</span></h2>
                        <p>Ingresá tus datos y te contactaremos a le brevedad</p>
                    </div>
                    <TextField
                        className='formContact_name'
                        label="Nombre y Apellido"
                        variant="outlined"
                        name='user_name'
                    />
                    <TextField
                        className='formContact_email'
                        label="Email"
                        type='email'
                        variant="outlined"
                        name='user_email'
                    />
                    <TextField
                        className='formContact_amb'
                        label="Selecciona los ambiente"
                        select
                        variant="outlined"
                        name='user_ambientes'
                        SelectProps={{
                            native: true,
                        }}
                    >
                        {
                            ambientes.map((option, key) => (
                                <option key={key} value={option.value}>
                                    {option.value}
                                </option>
                            ))

                        }
                    </TextField>
                    <TextField
                        className='formContact_tel'
                        label="Teléfono"
                        type='tel'
                        variant="outlined"
                        name='user_telefono'
                    />
                    <TextField
                        className='formContact_tArea'
                        label="Mensaje"
                        defaultValue='Ingresa tu consulta y el horario que quieras que te llamemos'
                        multiline
                        rows={4}
                        name='message'
                    />
                    <div className='formContact_btn'>
                        <Button variant='outlined' className='btn' type='submit'>
                            Enviar
                        </Button>
                    </div>
                </form>
            </div>
    );
}

export default FormContact;