import { Button } from '@mui/material';
import './aboutUs.css';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';


const AboutUs = () => {
    return(
        <div className='aboutUs_container'>
            <div className='aboutUs_info_container'>
                <div className='aboutUs_title_container'>
                    <h2>Somos <span className='gold_letters'>Honolulu</span></h2>
                </div>
                <div className='aboutUs_text_container'>
                    <p>
                        Una empresa dedicada al diseño, desarrollo y ejecución de proyectos inmobiliarios en la ciudad de Mar del Plata. Con nuestro equipo nos enfocamos en crear espacios que inspiren y mejoren la calidad de vida de las personas. 
                        Trabajamos con los materiales de más alta calidad, lo que nos permite garantizar la durabilidad, tanto de los proyectos como de la relación con nuestros clientes.
                        Apasionados por el diseño y la mejora constante.
                    </p>
                </div>
            </div>
            <div className='aboutUs_ubi_container'>
                <div className='aboutUs_map_container'>
                    <iframe 
                        src={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3144.912335781125!2d-57.54449993638648!3d-37.97917510891869!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9584dbe528ed2689%3A0xc2fc0353c269602b!2sStrobel%203562%2C%20B7600%20Mar%20del%20Plata%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1684710532805!5m2!1ses-419!2sar"}
                        width="950" 
                        height="450" 
                        allowFullScreen="" 
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade"
                        className='aboutUs_map'
                        >
                    </iframe>
                </div>  
                <div className='aboutUs_ubi_info_container'>
                    <div className='ubi_info_container'>
                        <h3>Nuestro espacio de trabajo</h3>
                        <div className='ubi'>
                            <span>
                                <RoomIcon />
                            </span>
                            <p>
                                Calle M.Strobel 3562 oficina 102, <br/>
                                Mar del Plata, Buenos Aires.
                            </p>
                        </div>
                        <div>
                            <Link to={'/Contacto'}>
                                <Button variant='outlined'>Contactanos</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;