import { useEffect, useState } from 'react';
import './homePage.css';


function HomePage() {
  const [metrosConstruidos, setMetrosConstruidos] = useState(0);
  const [metrosXContruir, setMetrosXContruir] = useState(0);
  const [deptEntregados, setDeptEntregados] = useState(0);

  const contadorPlus10 = (numero, limite, setContador) => {
    setInterval(() => {
      if(numero == limite) {
        clearInterval(contadorPlus10);
      } else {
        setContador(numero+= 10);
      }
    }, 0.5);
  }

  const contadorPlus1 = (numero, limite, setContador) => {
    setInterval(() => {
      if(numero > limite) {
        clearInterval(contadorPlus1);
      } else {
        setContador(numero++);
      }
    }, 0.5);
  }

  useEffect(() => {
    setTimeout(() => {
      contadorPlus10(metrosConstruidos, 4100, setMetrosConstruidos);
      contadorPlus10(metrosXContruir, 3540, setMetrosXContruir);
      contadorPlus1(deptEntregados, 34, setDeptEntregados);
    }, 1500);
  }, []);
  return (
    <div className="homePage_container">
      <div className="homePage_slider">
        <img src='../../assets/Logo/costa.webp' />
        <div className='img_logo_container'>
          <img src='../../assets/Logo/LogoDorado.png' width={'25%'} height={'100%'} alt='Logo de la pagina'/>  
          <div className='logo_title_container'> 
            <h1>HONOLULU EDIFICIOS</h1>
            <p>
              Construyamos un futuro juntos
            </p>
          </div>
        </div>
        <div className='metros_construidos_container'>
          <div className='metros'>
            <span>{metrosConstruidos}m²</span>
            <span>CONSTRUIDOS</span>
          </div>
          <div className='metros'>
            <span>{metrosXContruir}m²</span>
            <span>EN CONSTRUCCIÓN</span>
          </div>
          <div className='metros'>
            <span>{deptEntregados}</span>
            <span>DEPT. ENTREGADOS</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
