import './developments.css';
import { useEffect, useState } from 'react';
import BuildingPreview from '../buildingPreview/buildingPreview';
import developmentsDesktop from '../../jsonObjects/jsonDesktop/developments.json';
import developmentsMobile from '../../jsonObjects/jsonMobile/developments.json';

const Developments = () => {
    const [developments, setDevelopments] = useState(developmentsMobile);

    const handlePhotos = () => {
        if(window.innerWidth > 1100)
            setDevelopments(developmentsDesktop);
        else    
            setDevelopments(developmentsMobile);
    }

    useEffect( () => {
        window.addEventListener('resize', handlePhotos);
    }, []);
    return(
        <div className='developments_container'>
            {
                developments.map((value, key) => (
                    <BuildingPreview key={key} building={value} />
                ))
            }
        </div>
    );
}

export default Developments;