import './buildingSoldOutPage.css';
import Divider from '@mui/material/Divider';
import BuildingPreview from '../buildingPreview/buildingPreview';

function BuildingSoldOutPage({building}) {
  return (
    <>
        <div className='title_container'>
            <Divider className='title_container_divider'/>
            <h2 className='title_container_title'>
                {building.name}
            </h2>
            <Divider className='title_container_divider'/>
        </div>
        <div className='carac_container'>
            <div className='carac_container_info'>
               <p dangerouslySetInnerHTML={{__html:building.caracInfo.text}}></p>
            </div>
            <div className='carac_container_img'>
                <img src={building.caracInfo.imgBottomUrl} width={'90%'} height={'100%'} loading='lazy' className='img_blur'alt='Fachada del edificio'/>
                <img src={building.caracInfo.imgTopUrl} width={'90%'} height={'100%'} loading='lazy' className='img_top'alt='Fachada del edificio'/>
            </div>
        </div>
        <div className='buildingsPages_container'>
            <div className='title_container'>
                <h2 className='title_container_title'>Conoce nuestras unidades disponibles</h2>
            </div>
            <div className='buildingsPages_cards'>
                {
                    building.buildings.map((value, key) => (
                        <BuildingPreview key={key} building={value} cardMode={true}/>
                    ))
                }
            </div>
        </div>
    </>
  );
}

export default BuildingSoldOutPage;